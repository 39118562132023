import React, { useEffect, useContext } from 'react';
import localForage from 'localforage';
import moment from 'moment';

import { UserContext } from '../providers/user';
import TwitchButton from './TwitchButton';
import UserInfos from './UserInfos';
import { login, getCurrentUser } from '../server-config/auth';
import "./Login.css"

function handleAuthLogin(twitchToken, dispatchUser) {
    let apiToken;
    localForage.getItem('api_token')
        .then(token => {
            if (token !== null && (token.expiration_date - 3600 * 2) > moment().unix()) {
                return token;
            }
            return login(twitchToken);
        }).then(({ access_token, expires_in, expiration_date }) => {
            localForage.setItem('api_token', { access_token, expires_in, expiration_date: expiration_date ? expiration_date : (moment().unix() + expires_in) });
            apiToken = access_token;
            return getCurrentUser(access_token);
        }).then(data => {
            dispatchUser({ type: 'merge', payload: { ...data, token: twitchToken, apiToken: apiToken } });
        })
        .catch(error => {
            console.warn('LOGIN ERROR', error);
            localForage.clear();
            dispatchUser({ type: 'reset' });
        });
}

function LoginButton() {
    const { profile, dispatchUser } = useContext(UserContext);
    const { token, username } = profile || {};

    useEffect(() => {
        if (token) {
            handleAuthLogin(token, dispatchUser)
        }
    }, [token, dispatchUser]);

    if (!token) {
        return (
            <TwitchButton />
        );
    }

    if (token && !username) {
        return (<div className='login-container'>
            <div className='user-infos user-infos-loading'>
                <div
                    className='user-infos-image'
                />
                <div className='user-infos-name'>
                    <div className='user-infos-name-welcome' />
                    <div className='user-infos-name-value' />
                </div>
            </div>
        </div>)
    }

    return (
        <UserInfos />
    );

}

export default LoginButton;