import { useContext } from 'react';

import { UserContext } from '../providers/user';
import fetch from '../server-config/easy-fetch';

export function useTwitchFetch(fetchConf, data, options) {
    const { profile } = useContext(UserContext);
    const token = (profile || {}).token;

    const newOptions = { ...options };
    newOptions.headers = (options || {}).headers || {};
    newOptions.headers['Client-ID'] = process.env.REACT_APP_CLIENT_ID;
    newOptions.headers.Authorization = options && options.headers && options.headers.Authorization || ('OAuth ' + token);
    return fetch(fetchConf, data, newOptions);
}

export function twitchFetch(fetchConf, data, options) {
    const newOptions = { ...options };
    newOptions.headers = (options || {}).headers || {};
    newOptions.headers['Client-ID'] = process.env.REACT_APP_CLIENT_ID;
    newOptions.headers.Authorization = options && options.headers && options.headers.Authorization;
    return fetch(fetchConf, data, newOptions);
}