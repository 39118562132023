import React, { useEffect, useState } from "react";
import "./StreamersInscrits.css";
import { listAllValidatedGroups } from "./server-config/invite";
import { useIsModo } from "./hooks/access";
import downloadData from "./utilities/download-data";


function Team({ accountPseudo, accountAvatar, invitedPseudo, invitedAvatar, cumulatedViews }) {
    return (
        <section className="team">
            <img src={accountAvatar} alt="" className="thumbnail thumbnail1" />
            <p className="pseudo pseudo1">{accountPseudo}</p>
            <img src={invitedAvatar} alt="" className="thumbnail thumbnail2" />
            <p className="pseudo pseudo2">{invitedPseudo}</p>
            <p className="view-count">Vues cumulées <span>{Number(cumulatedViews).toLocaleString('fr')}</span></p>
        </section>
    );
}

function csvExport(validated) {
    const header = ['AccountId;AccountPseudo;InvitedId;InvitedPseudo;CumulatedViews;Status'];
    return evt => {
        evt.preventDefault();

        const data = validated.map(({ accountId, accountPseudo, invitedId, invitedPseudo, cumulatedViews, status }) =>
            `${accountId};${accountPseudo};${invitedId};${invitedPseudo};${cumulatedViews};${status}`
        );
        const fileName = 'zlan-streamers.csv';

        downloadData(header.concat(data).join('\n'), fileName, 'text/csv');
    }
}

export default function StreamersInscrits() {
    const [validated, setValidated] = useState([]);
    const isModo = useIsModo();
    // const [waiting, setWaiting] = useState([]);

    useEffect(() => {
        listAllValidatedGroups()
            .then(list => list.filter(elt => elt.cumulatedViews >= 5000))
            .then(setValidated);
        // listInvites("AWAITING").then(setWaiting);
    }, []);

    return (
        <div className="container">
            <div className="content">
                <h1>Streamers pré-inscrits à GPC</h1>
                <div className="list">
                    <h2>Streamers en attente de validation</h2>
                    {isModo && <a className="btn action" onClick={csvExport(validated)} style={{ display: 'block', 'margin-bottom': '15px' }} >Exporter en CSV</a>}
                    <div className="teams">
                        {validated.map((elt, i) => <Team key={i} {...elt} />)}
                    </div>
                </div>
                {/* <div className="list">
                    <h2>Streamers en attente de validation</h2>
                    <div className="teams">
                        {waitingTeams.map((elt, i) => <Team key={i} {...elt} />)}
                    </div>
                </div> */}
            </div>
        </div>
    );
}
