import React, { useReducer, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";

import { useIsAuthenticated, useApiToken } from './hooks/access';
import { getCurrentGroup, createGroup, updateGroup } from "./server-config/inscription";

import Input, { CheckboxField as Checkbox } from "./components/InputField";
import "./Inscription.css"



function formReducer(state, { type, payload }) {
    switch (type) {
        case 'reset':
            return {};
        case 'replace':
            return payload;
        case 'field':
            return { ...state, [payload.field]: payload.value };
        default:
            throw 'Unhandled case ' + type;
    }
}

function FormField({ state, dispatch, name, type, ...others }) {
    let ChosedInput = type === "checkbox" ? Checkbox : Input;

    return (
        <ChosedInput
            value={state[name] !== undefined ? state[name] : type === "checkbox" ? false : ""}
            onChange={value => dispatch({ type: "field", payload: { field: name, value } })}
            name={name}
            type={type}
            {...others}
        />);
}

function SelectField({ state, dispatch, name, children, ...others }) {

    return (
        <select
            value={state[name] !== undefined ? state[name] : ""}
            onChange={evt => dispatch({ type: "field", payload: { field: name, value: evt.target.value } })}
            name={name}
            {...others}
        >{children}</select>);
}


function submit(e, state, token, reload) {
    e.preventDefault();
    e.stopPropagation();

    // "maniaplanet1", "maniaplanet2", "minecraft1", "minecraft2"
    if (["pseudo1", "nom1", "prenom1", "email1", "pseudo2", "nom2", "prenom2", "email2", "birthdate1", "birthdate2"].some(elt => !state[elt] || ('' + state[elt]).trim().length === 0)) {
        alert("Merci de renseigner tous les champs.")
        return;
    }

    // if (["age1", "age2"].some(elt => state[elt] < 16)) {
    //     alert("Vous devez avoir 16 ans ou plus afin de vous inscrire.")
    //     return;
    // }

    if (!state.groupId && ["reglement", "move-event", "mat-event", "pay-event", "cgu-event"].some(elt => !state[elt])) {
        alert("Vous devez accepter toutes les conditions avant de vous inscrire.")
        return;
    }

    const { reglement, "move-event": moveEvent, "mat-event": MatEvent, "pay-event": payEvent, "cgu-event": cguEvent, ...cleanState } = state;

    if (state.groupId) {
        updateGroup(cleanState, token)
            .then(() => {
                reload();
                // dispatch({ type: "field", payload: { field: "groupId", value: id } });
                alert("Modifications réussies");
            }).catch(err => {
                console.error(err);
                alert("Erreur lors des modifications :\n"
                    + "- êtes-vous déjà dans un groupe ?\n"
                    + "- cet email est-il déjà utilisé ?\n"
                    + "DEBUG\n"
                    + JSON.stringify(err, null, 2));
            });

    } else {
        createGroup(cleanState, token)
            .then(() => {
                reload();
                // dispatch({ type: "field", payload: { field: "groupId", value: id } });
                alert("Pré-inscription réussie");
            }).catch(err => {
                console.error(err);
                alert("Erreur à l'inscription :\n"
                    + "- êtes-vous déjà dans un groupe ?\n"
                    + "- cet email est-il déjà utilisé ?\n\n\n"
                    + "DEBUG\n\n"
                    + JSON.stringify(err, null, 4));
            });
    }
}

export default function Inscription() {
    const isAuth = useIsAuthenticated();
    const token = useApiToken();
    var now = new Date();
    var inscriptionEndsAt = new Date('11/11/2019 11:59:59 PM');

    const inscriptionClosed = ((inscriptionEndsAt - now)<=0);
    const [reloader, setReloader] = useState(0);
    const [state, dispatch] = useReducer(formReducer, {});

    useEffect(() => {
        if (isAuth) {
            getCurrentGroup(token)
                .then(group => dispatch({ type: "replace", payload: group }));
        }
    }, [isAuth, reloader]);


    return (
        <div className="container inscr">
            <div className="content">

                <h1>Pré-Inscription pour participer au GamePassChallenge{isAuth && inscriptionClosed}</h1>

                {!isAuth && <p>Vous devez vous connecter pour vous pré-inscrire.</p>}
                {inscriptionClosed && <div className="warning-elt">Les inscriptions sont fermées !</div>}
                {!!state.groupId && <div className="warning-elt">Vous êtes déjà inscrit à GamePassChallenge.</div>}
                {isAuth && !inscriptionClosed &&
                    <Fragment>
                        {state.groupId ? "" : <div className="warning-elt">Avant de procéder à l'inscription, assurez vous d'avoir au préalablement formé votre duo dans "Mes invitations".</div> }
                        <form className="" onSubmit={e => submit(e, state, token, () => setReloader(reloader + 1))}>
                            <div className="bloc columns2">
                                <h2>Joueur 1</h2>
                                <label>Pseudo Joueur 1*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="pseudo1" placeholder="Pseudo Joueur 1" />
                                <label>Nom Joueur 1*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="nom1" placeholder="Nom Joueur 1" />
                                <label>Prénom Joueur 1*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="prenom1" placeholder="Prénom Joueur 1" />
                                <label>Email Joueur 1*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="email1" placeholder="Email Joueur 1" />
                                <label>Date de naissance Joueur 1*</label>
                                <FormField state={state} dispatch={dispatch} type="date" name="birthdate1" placeholder="Age Joueur 1" />
                                {/*<label>Login Maniaplanet Joueur 1 <a className="text_blue" href="http://leetgamerz.net/fr/page/view/slug/maniaplanetid" target="_blank">Trouver son login</a></label>
                                <FormField state={state} dispatch={dispatch} type="text" name="maniaplanet1" placeholder="Login Maniaplanet Joueur 1" />
                                <label>Steam ID Joueur 1 <a className="text_blue" href="https://support.steampowered.com/kb_article.php?ref=1558-QYAX-1965&l=french" target="_blank">Trouver son Steam ID</a></label>
                                <FormField state={state} dispatch={dispatch} type="text" name="steam1" placeholder="Steam ID" />
                                <label>Pseudo Minecraft Joueur 1</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="minecraft1" placeholder="Pseudo Minecraft Joueur 1" />
                                <label>Taille T-shirt Joueur 1*</label>
                                <SelectField state={state} dispatch={dispatch} name="tshirt1">
                                    <option value="">--Sélectionner une taille--</option>
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                    <option value="XXL">XXL</option>
                                    <option value="XXXL">XXXL</option>
                                    <option value="NA">Non communiquée</option>
                                </SelectField>*/}
                            </div>

                            <div className="bloc columns2">
                                <h2>Joueur 2</h2>
                                <label>Pseudo Joueur 2*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="pseudo2" placeholder="Pseudo Joueur 2" />
                                <label>Nom Joueur 2*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="nom2" placeholder="Nom Joueur 2" />
                                <label>Prénom Joueur 2*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="prenom2" placeholder="Prénom Joueur 2" />
                                <label>Email Joueur 2*</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="email2" placeholder="Email Joueur 2" />
                                <label>Date de naissance Joueur 2*</label>
                                <FormField state={state} dispatch={dispatch} type="date" name="birthdate2" placeholder="Age Joueur 2" />
                                {/*<label>Login Maniaplanet Joueur 2 <a className="text_blue" href="http://leetgamerz.net/fr/page/view/slug/maniaplanetid" target="_blank">Trouver son login</a></label>
                                <FormField state={state} dispatch={dispatch} type="text" name="maniaplanet2" placeholder="Login Maniaplanet Joueur 2" />
                                <label>Steam ID Joueur 2 <a className="text_blue" href="https://support.steampowered.com/kb_article.php?ref=1558-QYAX-1965&l=french" target="_blank">Trouver son Steam ID</a></label>
                                <FormField state={state} dispatch={dispatch} type="text" name="steam2" placeholder="Steam ID" />
                                <label>Pseudo Minecraft Joueur 2</label>
                                <FormField state={state} dispatch={dispatch} type="text" name="minecraft2" placeholder="Pseudo Minecraft Joueur 2" />
                                <label>Taille T-shirt Joueur 2*</label>
                                <SelectField state={state} dispatch={dispatch} name="tshirt2">
                                    <option value="">--Sélectionner une taille--</option>
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                    <option value="XXL">XXL</option>
                                    <option value="XXXL">XXXL</option>
                                    <option value="NA">Non communiquée</option>
                                </SelectField>*/}
                            </div>

                            <div className="bloc">
                                <p>* : Obligatoire</p>
                                {!state.groupId && <Fragment>
                                    <div className="checkbox">
                                        <FormField state={state} dispatch={dispatch} className="styled-checkbox" name="reglement" id="reglement" type="checkbox" />
                                        <label htmlFor="reglement">J'ai conscience que cet évènement sera filmé et retransmis en direct sur Internet et que mon image pourra être utilisée pendant et après l'évènement en cas de sélection.</label>
                                    </div>
                                    <div className="checkbox">
                                        <FormField state={state} dispatch={dispatch} className="styled-checkbox" name="move-event" id="move-event" type="checkbox" />
                                        <label htmlFor="move-event">Je m'engage à pouvoir me déplacer à Lyon</label>
                                    </div>
                                    <div className="checkbox">
                                        <FormField state={state} dispatch={dispatch} className="styled-checkbox" name="mat-event" id="mat-event" type="checkbox" />
                                        <label htmlFor="mat-event">Je m'engage à pouvoir venir avec mon propre matériel (PC, écran, câbles...)</label>
                                    </div>
                                    <div className="checkbox">
                                        <FormField state={state} dispatch={dispatch} className="styled-checkbox" name="pay-event" id="pay-event" type="checkbox" />
                                        <label htmlFor="pay-event">Je m'engage à régler ma participation (40€ par personne) à l'événement sous 72h aprpès la phase de sélection.</label>
                                    </div>
                                    <div className="checkbox">
                                        <FormField state={state} dispatch={dispatch} className="styled-checkbox" name="cgu-event" id="cgu" type="checkbox" />
                                        <label htmlFor="cgu">J'accepte les <Link to="/cgu">conditions générales d'utilisation</Link></label>
                                    </div>
                                </Fragment>}
                                <input type="submit" name="" value={state.groupId ? "Modifier nos informations" : "Nous inscrire"} />
                            </div>
                        </form>
                    </Fragment>}
            </div>
        </div>
    );

}
