import fetch from './easy-fetch';
import { apiRoot } from './index';

export function inviteToGroup(partnerId, accessToken) {
    return fetch({
        url: apiRoot + 'me/group',
        method: 'POST'
    }, { account_id: +partnerId }, { headers: { Authorization: accessToken } });
}

export function listInvites(status, accessToken) {
    return fetch({
        url: `${apiRoot}me/group/invites?status=${status}`,
        method: 'GET'
    }, null, { headers: { Authorization: accessToken } })
        .then(list => list.sort((a, b) => b.cumulatedViews - a.cumulatedViews));
}

export function acceptInvite(account_id, accessToken) {
    return fetch({
        url: `${apiRoot}me/group/invites/${account_id}`,
        method: 'PUT'
    }, { status: "VALIDATED" }, { headers: { Authorization: accessToken } });
}

export function blockInvite(account_id, accessToken) {
    return fetch({
        url: `${apiRoot}me/group/invites/${account_id}`,
        method: 'PUT'
    }, { status: "REJECTED" }, { headers: { Authorization: accessToken } });
}

export function declineInvite(account_id, accessToken) {
    return fetch({
        url: `${apiRoot}me/group/invites/${account_id}`,
        method: 'DELETE'
    }, null, { headers: { Authorization: accessToken } });
}

export function listAllValidatedGroups() {
    return fetch({
        url: `${apiRoot}groups`,
        method: 'GET'
    })
        .then(list => list.sort((a, b) => b.cumulatedViews - a.cumulatedViews));
}