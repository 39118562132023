import React from 'react';
import { HashRouter as Router } from "react-router-dom";
import UserProvider from './providers/user';

import Layout from './Layout';

export default function App() {
    return (
        <UserProvider basename={process.env.PUBLIC_URL}>
            <Router>
                <Layout />
            </Router>
        </UserProvider>
    );
}