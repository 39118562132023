import React from "react";


function onFieldChange(cb) {
    return event => {
        // event.preventDefault();
        // event.stopPropagation();
        cb(event.target.value);
    }
}

export default function Field({ value, onChange, ...others }) {
    return (
        <input value={value} onChange={onFieldChange(onChange)} {...others} />
    );
}

function onCheckboxFieldChange(cb) {
    return event => {
        // event.preventDefault();
        // event.stopPropagation();
        // console.log(event.target.checked, event.target);
        cb(event.target.checked);
    }
}


export function CheckboxField({ value, onChange, ...others }) {
    return (
        <input checked={value} onChange={onCheckboxFieldChange(onChange)} {...others} />
    );
} 