import fetch from './easy-fetch';
import { apiRoot } from './index';

export function login(token) {
    return fetch({
        url: apiRoot + 'account/login',
        method: 'POST'
    }, { twitch_token: token });
}

export function getCurrentUser(accessToken) {
    return fetch({
        url: apiRoot + 'me',
        method: 'GET'
    }, null, { headers: { Authorization: accessToken } });
}
