import React, {Fragment} from "react";
import SideMenu from "./SideMenu";
import Routes from "./Route";
import "./Layout.css"

export default function Layout() {
    return (
        <Fragment>
            <SideMenu/>
            <Routes/>
        </Fragment>
        );
}