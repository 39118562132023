import React, { useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";

import localforage from "localforage";
import { UserContext } from './providers/user';

export default function TwitchToken({ match: { params } }) {
    const { dispatchUser } = useContext(UserContext);

    useEffect(() => {
        // console.log('params', params.token, profile, dispatchUser);
        if (!params.token) {
            return;
        }
        const twitchResponse = params.token.split('&').reduce((acc, elt) => {
            const [key, value] = elt.split('=');
            acc[key] = value;
            return acc;
        }, {});

        localforage.getItem('nonce').then(nonce => {
            const { scope, access_token: token, state } = twitchResponse;
            if (state !== nonce) {
                dispatchUser({ type: 'reset' });
                // addErrorMessage('error.twitchAuth');
                localforage.clear();
            } else {
                dispatchUser({ type: 'merge', payload: { scope, token } });
                // dispatchData('profile', { scope, token });
                localforage.setItem('twitch_data', { token, scope });
            }
        });
        // navigate('home')
        // replace(`${__BASE_URL__}home`);

    }, [params.token]);
    return <Redirect to="/" />;
    // return null;
}
