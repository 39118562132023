import React from "react";
import { Link } from "react-router-dom";
import logo from "./assets/images/logo_gpc_2000px_rvb.png";
import Login from "./components/Login";
import { useIsAuthenticated, useIsModo } from './hooks/access';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faUserPlus, faEnvelope, faHome, faList } from '@fortawesome/free-solid-svg-icons'

export default function SideMenu() {
    const isAuth = useIsAuthenticated();
    const isModo = useIsModo();
    return (
        <div className="side-menu-left">

            <div className="logo">
                <Link to="/"><img src={logo} alt="Logo ZLAN" /></Link>
            </div>

            <Login />

            <nav className="side-nav">
                <ul>
                    <li><Link to="/"><FontAwesomeIcon icon={faHome} /> <span>Accueil</span></Link></li>
                    {isAuth && <li><Link to="/pre-inscription"><FontAwesomeIcon icon={faUserPlus} /> <span>Inviter mon partenaire</span></Link></li>}
                    {isAuth && <li><Link to="/my-invites"><FontAwesomeIcon icon={faEnvelope} /> <span>Mes invitations</span></Link></li>}

                    <li><Link to="/inscription"><FontAwesomeIcon icon={faUserPlus} /> <span>Inscription </span></Link></li>
                    <li><Link to="/cgu"><FontAwesomeIcon icon={faGamepad} /> <span>Règlement des inscriptions</span></Link></li>
                    <li><a target="_blank" href="http://gamepasschallenge.fr/inscription" ><FontAwesomeIcon icon={faGamepad} /> <span>Comment m'inscrire</span></a></li>

                    {isAuth && isModo && <li><Link to="/inscriptions"><FontAwesomeIcon icon={faList} /> <span>Liste des inscrits</span></Link></li>}
                    {isAuth && isModo && <li><Link to="/streamers-inscrits"><FontAwesomeIcon icon={faGamepad} /> <span>Streamers inscrits</span></Link></li>}

                </ul>
            </nav>

        </div>
    );
}
