import React from 'react';
import localforage from 'localforage';
import { v4 as uuid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch } from '@fortawesome/free-brands-svg-icons'

function doLogin() {
    const state = uuid();
    localforage.setItem('nonce', state).then(() => {
        // eslint-disable-next-line
        location.href = `https://api.twitch.tv/kraken/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ROOT_URL}&response_type=token&scope=user_read&state=${state}`;
    })
}

function TwitchButton() {
    return (
        <div className="twitch-panel">
            <a onClick={doLogin} className="twitch-login"><FontAwesomeIcon icon={faTwitch} /> <span>{'Se connecter'}</span></a>
        </div>

        // <div className='twitch-container'>
        //     <button
        //         className='twitch-sign-in'
        //         onClick={doLogin}
        //     >
        //         <FontAwesome name='twitch' />
        //         <span>
        //             {'Se connecter'}
        //         </span>
        //     </button >
        // </div>
    );
}


TwitchButton.displayName = 'TwitchButton';

export default TwitchButton;
