import { useContext } from 'react';

import { UserContext } from '../providers/user';


function useApiToken() {
    const { profile } = useContext(UserContext);
    const { apiToken } = profile || {};
    return apiToken;
}

function useTwitchToken() {
    const { profile } = useContext(UserContext);
    const { token } = profile || {};
    return token;
}

function useTwitchId() {
    const { profile } = useContext(UserContext);
    const { twitchId } = profile || {};
    return twitchId;
}

function useUserGrade() {
    const { profile } = useContext(UserContext);
    const grade = (profile || {}).grade || 0;

    return grade;
}

function useIsAuthenticated() {
    return useUserGrade() >= 1000;
}

function useIsAdmin() {
    return useUserGrade() >= 3000;
}

function useIsModo() {
    return useUserGrade() >= 2000;
}

export {
    useIsAuthenticated,
    useIsAdmin,
    useIsModo,
    useApiToken,
    useTwitchToken,
    useTwitchId
}