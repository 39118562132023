import React, {Fragment} from "react";

import "./Cgu.css"

export default function Cgu() {
    return (
        <div className="container cgu">
          <div className="content">
          <h1>DEROULE DES INSCRIPTIONS</h1>
            <h3>1- Phase de pré-inscription</h3>
            <p>
            La phase de pré-inscription se déroule du Vendredi 8 novembre 20h00 au lundi 11 novembre 23H59.<br />
            Pendant la pré-inscription vous seront demandé obligatoirement un login “Twitch” pour les deux membres de votre duo, vos informations personnelles qui ne serviront que dans le cadre du tournoi. Voir la section sur les informations personnelles <br />
            Tout Duo n’étant pas complètement inscrit (manque d’informations, duo pas relié ou inscription solo uniquement) sera à la seule charge de l’organisation potentiellement exclus de la phase de sélection.
            </p>
            <h3>2- Phase de sélection</h3>
            <p>
            A l’issue de cette phase de pré-inscription auront lieux trois phases de sélection : <br/>
            <ul>
            <li>PHASE A - Streamers : Seront sélectionnés maximum 20 duos basés sur le nombre maximal de vues de leur chaîne Twitch associée, qui seront dans l’obligation de streamer au moins 80% de leur présence sur l’évènement  sur cette même chaîne Twitch</li>
            <li>PHASE B - Non Streamers : Seront tirés au sort un minimum de 28 duos sur la liste restante des duos non sélectionnés en phase A.</li>
            <li>PHASE C - Liste d’attente : Seront tirés au sort un minimum de 15 duos en liste d’attente, non sélectionnés en phase A et phase B.</li>
            </ul>
            Cette phase de sélection aura lieu le jeudi 14 novembre en direct sur le live de ZeratoR à partir de 20H00.<br/>
            La liste finale des joueurs sélectionnés sera communiquée le jeudi 14 novembre à partir de 21H00 à tous les duos selectionnés en Phase A et Phase B. Un email sera envoyé aux deux membres du Duo avec toutes les informations nécessaires.
            </p>

            <h3>3- Phase d’inscription</h3>
            <p>
            Dès la réception de l’email vous seront accordés 72 Heures afin de valider votre inscription via un lien Weezevent pour le paiement des frais d’inscriptions qui validera votre inscription définitive, ainsi que l’obligation de rejoindre le serveur discord officiel de l'évènement. L’email contiendra toutes les informations nécessaires pour vous inscrire. Vous aurez l’obligation de vérifier vos e-mails dans vos spams ou autres dossiers et l’organisateur aura pour charge de décider lui-même si un report est accepté dans les cas où l’inscription n’est pas validée sous 72H pour un quelconque problème.
            <br/>
            Après 72H , tous les duos qui n’auront pas confirmé leur inscription perdront tout droit d’inscription et seront contactés dans l’ordre de sélection de la phase C tous les duos en attente.
            </p>


            <h1>CONDITIONS GENERALES DES PRE-INSCRIPTIONS AU GAMEPASS CHALLENGE</h1>

            <h3>Article 1 - Définition et conditions du tirage au sort (PHASE B ET C) </h3>
            <p>La société ZQSD Productions, au capital de 1000 Euros est inscrite au registre du commerce et des sociétés de LYON 
              sous le numéro SIREN 819 213 224 000 10, ayant son siège social au 26 RUE EMILE DECORPS 69100 VILLEURBANNE, 
              organise un tirage au sort dans le cadre d’une inscription ouverte au public pour l'événement “GAMEPASS CHALLENGE” qui est une LAN party commentée par ZeratoR dont la première édition se déroulera à Lyon le week-end du 14/15 Décembre 2019.<br />
            Ce tirage au sort permet de pré-sélectionner une liste de participants qui devront valider leur participation financière avant toute inscription définitive.
            </p>
            <ul>
              <li>La société ZQSD Productions est désignée également ci-après comme : « l’Organisateur, la Société Organisatrice, les Organisateurs, le Professionnel ».</li>
              <li>Le « Participant » au tirage au sort-tirage au sort est désigné également ci-après comme « le Participant, l’Utilisateur, le Contributeur, le Joueur ».</li>
              <li>Le « Gagnant » au tirage au sort est désigné également ci-après comme « le Gagnant ».</li>
              <li>Le « Game Pass Challenge » , nom de l'évènement sera désigné également ci-après comme « GPC ».</li>
            </ul>

            <h3>Article 2 - Conditions de participation</h3>
            <p>Les inscriptions sont ouvertes à l’adresse https://inscriptions.gamepasschallenge.fr pour toute personne physique, à la condition d’avoir plus de 16 ans ( avec une autorisation d’un tuteur légal obligatoire jusqu’à 18 ans), de disposer d’une connexion à l’Internet et d’un compte Twitch, et après acceptation des conditions de participation conformément à la loi informatique et liberté.<br /><br />

            Le participant s’engage à pouvoir se déplacer au lieu de l’évènement, d’amener son propre matériel (GPC est une LAN “BYOC”) et ne pourra pas streamer pendant l’évènement sans accord de l'organisation si issu des phases B et C.<br /><br />

            Une seule participation par personne physique est acceptée. L’adresse e-mail devra être confirmée. Sont exclus de toute participation au tirage au sort les membres du personnel des sociétés ZQSD Productions et ZT Productions ainsi que les membres de leur famille.
            </p>

            <h3>Article 3 - Dates du tirage au sort</h3>
            <p>
            Date de début des pré-inscriptions: 8 Novembre 2019 à 20h00<br />
            Date de fin  des pré-inscriptions : 11 Novembre 2019 à 23h59<br />
            Date du tirage au sort: 14 Novembre 2019 à 20h00<br />
            Date de désignation du/des Gagnant(s) : 14 Novembre 2019 à 21h (envoie des emails)
            </p>

            <h3>Article 4 - Modalités de participation</h3>
            <h4>4.1) Conditions de dépôt de candidature</h4>
            <p>
            Afin que la participation soit validée par l’Organisateur, il y a deux étapes à respecter impérativement <br />
            le Participant devra :<br />
            a) Se connecter au site internet GPC en cliquant sur l’adresse suivante accessible pendant toute la durée du tirage au sort et communiquée par ZeratoR http://inscriptions.gamepasschallenge.fr/<br />
            b) S’inscrire en remplissant le formulaire d’inscription complet, en remplissant ses informations de contact ainsi que la validation de l’email dédié
            </p>
            <h4>4.2) Garanties et responsabilité sur la validité des candidatures</h4>
            <p>
            ZQSD PRODUCTIONS se réserve le droit d’annuler, de mettre fin ou modifier cette opération si elle ne se déroule pas comme convenu : <br />
            Tout virus, bug, violation, intervention non autorisée, fraude, action d’un concurrent, problème technique ou autre cause hors du contrôle de ZQSD PRODUCTIONS altère et affecte l’administration, la sécurité, l’équité, l’intégrité, ou la conduite de ZQSD PRODUCTIONS.<br />
            De façon générale, les Participants garantissent les Organisateurs du présent tirage au sort contre tous recours, actions ou réclamations que pourraient former, à un titre quelconque, tous tiers, au titre de toutes les garanties et engagements pris.<br /><br />

            Toute participation incomplète ou erronée sera rejetée, sans que la responsabilité de l’Organisateur puisse être engagée. Toute déclaration mensongère d’un participant entraînera son exclusion du tirage au sort sans que la responsabilité de l’Organisateur puisse être engagée.<br />
            Ainsi, la Société Organisatrice se réserve le droit sans réserves de modérer à postériori et de ne pas valider, voir exclure, supprimer du tirage au sort, tout participant qui ne respecterait pas une des conditions du présent règlement.
            </p>

            <h3>Article 5 - Modalités du tirage au sort</h3>
            <p>
            L’inscription à GPC se faisant par “duo” de deux participants physiques, le tirage au sort désignera 28 duos de “gagnants” en PHASE B puis 15 duos en phase C qui pourront participer à GPC une fois que leur participation financière sera validée.<br /><br />

            Le tirage au sort pour désigner les duos vainqueurs sera effectué à la seule discrétion de l'organisation en direct sur le live de ZeratoR (twitch.tv/zerator).<br /><br />

            La société ZQSD PRODUCTIONS se réserve le droit de changer le nombre de gagnans sans préavis afin d’adapter le nombre total de participants à la GPC dans la limite maximale de 48 “duos”.
            </p>

            <h3>Article 6 - Gagnants</h3>
            <p>Les gagnants du tirage au sort auront 72 heures pour valider leur inscription définitive en s’affranchissant des droits d’inscription payants pour leur duo. Dans le cas où l’une des deux personnes ne paie pas les droits d’entrée, leur place pourra être assignée à d’autres personnes sans possibilité de recours par les “gagnants”. De la même manière, le tirage au sort n’offre aux participants que la possibilité de s’inscrire à GPC, et en aucun cas une place offerte. Le tirage au sort ne peut donner lieu à aucune contestation sur la nature des dotations ni à la remise d’une contrepartie de quelque nature que ce soit.<br /><br />

            Les Gagnants seront alors invité à fournir leur coordonnées précises : nom,prénom, date de naissance, adresse, ville, code postal, pays, n° de téléphone, adresse email par email à : contact@gamepasschallenge.fr
            Si les informations communiquées par le participant sont incomplètes et/ou ne permettent pas de l’informer de son gain, il perdra la qualité de Gagnant et ne pourra effectuer aucune réclamation.
            L’Organisateur ne pourra être tenu responsable dans le cas de défiances techniques quant à cette notification électronique de gain. Sans communication de ces informations de la part du Gagnant sous 72 heures, il perdra sa qualité de Gagnant.
            </p>

            <h3>Article 7 - Streamers selectionnés en phase A</h3>
            <p>Tous les duos qui sont selectionnés en phase A acceptent les points suivants : 
            <ul>
              <li>Les deux streamers sont autorisés à streamer sur leurs chaines utilisées pour la pré-inscription.</li>
              <li>L'organisation pourra demander aux streamers de couper leur stream à n'importe quel moment de l'évènement en cas de problème Internet, d'organisation ou de DDOS. Tout streamer qui ne se conformera pas à la demande sera immédiatement expulsé.</li>
              <li>Ils devront streamer un minimum de 80% de leur présence en compétition (avant élimination) sur cette même chaine. (Pour des raisons simples, la selection est effectuée au nombre de vues principalement, vous ne pouvez donc pas vous inscrire avec une chaine avec un haut nombre de vue mais streamer sur une autre petite chaine)</li>
              <li>Les streamers auront l'obligation d'utiliser les overlays et assets fournis par l'organisation pendant l'évènement, ceci inclue les eventuels partenaires.</li>           
              <li>En cas de conflit majeur (à la seule charge de l'organisation d'en décider) entre les partenaires d'un streamer et les partenaires de l'évènement, vous êtes invité à nous contacter à contact@gamepasschallenge.fr pour discuter au cas par cas.</li>
            </ul>


            </p>

            <h3>Article 8 - Données nominatives et personnelles</h3>
            <p>
            Les Joueurs autorisent par avance du seul fait de leur participation, que les Organisateurs utilisent librement à des fins administratives, quel qu’en soit le support, toutes les informations nominatives communiquées pour le compte de ceux-ci et sur tous supports.Les données à caractère personnel recueillies vous concernant sont obligatoires et nécessaires pour le traitement de votre participation au tirage au sort. Elles sont destinées aux Organisateurs, ou à des sous-traitants et/ou des prestataires pour des besoins de gestion.<br />
            Conformément à la réglementation en vigueur, les informations collectées sont destinées exclusivement aux Organisateurs et elles ne seront ni vendues, ni cédées à des tiers, de quelque manière que ce soit.
            Les renseignements communiqués par le participant sont destinés à l'usage de ZQSD PRODUCTIONS dans le cadre de l'accès à son service conformément aux conditions générales de vente et dans le cadre de la gestion du présent tirage au sort.
            </p>

            <h3>Article 9 - Responsabilités et droits</h3>
            <p>Les Organisateurs :</p>
            <ul>
              <li>Se réservent le droit de modifier, de proroger, d’écourter, de limiter les gains ou d’annuler ce tirage au sort en cas de force majeure tel que défini par la jurisprudence. En conséquence, leur responsabilité ne saurait être engagée de ce fait.</li>
              <li>Ne pourront être tenus responsables de l’utilisation frauduleuse des droits de connexion ou d’attribution du lot d’un Participant.</li>
              <li>Dégagent toute responsabilité en cas de dysfonctionnement du réseau internet, des lignes téléphoniques, du matériel de réception empêchant le bon déroulement du tirage au sort. En outre, leur responsabilité ne pourra en aucun cas être retenue pour des problèmes d’acheminement ou de perte de courrier électronique ou postal.</li>
              <li>Dégagent toute responsabilité en cas de défaillance technique, anomalie, matérielle et logicielle de quelque nature (virus, bogue...) occasionnée sur le système du Participant, à leur équipement informatique et aux données qui y sont stockées et aux conséquences pouvant en découler sur leur activité personnelle, professionnelle ou commerciale.</li>
            </ul>

            <h3>Article 10 - Conditions d’exclusion</h3>
            <p>La participation à ce tirage au sort implique l’acceptation pleine et entière des modalités énoncées dans le présent règlement sans aucune réserve ni condition préalable du Participant, le non respect dudit règlement, entraînant l’exclusion du tirage au sort, la nullité pure et simple de sa participation et de l’attribution des lots.</p>

            <h3>Article 11 - Juridictions compétentes</h3>
            <p>Le présent règlement est soumis à la loi française.Les partis s’efforcent de résoudre à l’amiable tout différend né de l’interprétation ou de l’exécution du présent règlement. Si le désaccord persiste, il sera soumis aux tribunaux compétents.Le Participant reconnaît avoir pris connaissance du présent règlement, de l’accepter sans réserves et de s’y conformer.</p>
          </div>
        </div>
        );
}
