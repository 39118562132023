import React from "react";
import SimpleSlider from "./components/ImageExpand";
import logo from "./assets/images/logo_gpc_2000px_rvb.png";
import button1 from "./assets/images/bouton_preinscrire.png";
import button2 from "./assets/images/bouton_conditions.png";
import button3 from "./assets/images/bouton_regles.png";
import arbre from "./assets/videos/arbre.mp4";
import pickban from "./assets/videos/pickban.mp4";
import { Link } from "react-router-dom";
import "./Home.css"

export default function Home() {

    return (
        <div className="container home">

            <div className="content">

                <img className="logo-home" src={logo} alt="" />

                <div className="bloc">
                    <p>Le GamePass Challenge #GPChallenge est une LAN party commentée par ZeratoR dont la première édition se déroulera à  Lyon le week-end du 14-15 décembre 2019.</p>
                    <p>La compétition #GPChallenge verra s'affronter 96 joueurs répartis dans 48 équipes qui s’affronteront sur 7 jeux différents pendant 2 jours : Age of Empire II HD Edition, Worms W.M.D, Rocket League, Minecraft, Forza Lego Edition, Sea of Thieves et Human Fall Flat.</p>
                    <p>Tout au long de la LAN, ZeratoR assurera le commentaire et le stream des différentes étapes du tournoi sur sa propre chaîne twitch : <a href="https://twitch.tv/ZeratoR" target="_blank">https://twitch.tv/ZeratoR</a>.</p>
                    <p>30.000€ seront mis en jeu pour les 4 premières équipes à se hisser sur le podium. Les gains seront répartis de la façon suivante :</p>
                    <p>
                        1ère place : <b>15.000€</b><br />
                        2ème place : <b>7.000€</b><br />
                        3ème place : <b>4.000€ </b><br />
                        4ème place : <b>4.000€ </b>
                    </p>
                    <p>Si vous souhaitez participer, vous êtes grandement invité à lire attentivement les règles de #GPChallenge et les règles du tournoi avant de vous inscrire.</p>
                </div>

                <div className="bloc">
                    <h2>A propos du #GPChallenge</h2>

                    <ul>
                        <li>Avoir plus de 16 ans ( avec une autorisation d’un tuteur légal obligatoire jusqu’à 18 ans)</li>
                        <li>Pouvoir se déplacer et trouver un hébergement sur Lyon</li>
                        <li>Vous ne pourrez stream que si vous êtes sélectionnés en PHASE A (Streamers)</li>
                        <li>Pas de spectateurs sur place</li>
                        <li>Une LAN “BYOC” (“bring Your Own Computer”) : amener son propre ordinateur et matériel</li>
                    </ul>
                    <Link to="/inscription"><img className="logo-home" src={button1} alt="" /></Link>
                

                
                <Link to="/cgu"><img className="logo-home" src={button2} alt="" /></Link>
                <a target="_blank" href="http://gamepasschallenge.fr/regles"><img className="logo-home" src={button3} alt="" /></a>
                </div>
            </div>
        </div>
    );
}
