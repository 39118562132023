import React, { Fragment, useEffect, useState } from "react";
import "./MyInvites.css"
import { useIsAuthenticated, useApiToken, useTwitchId } from './hooks/access';
import { Redirect } from "react-router-dom";
import { listInvites, acceptInvite, declineInvite, blockInvite } from "./server-config/invite"


function Invite({ accountId, invitedId, status, updateTimestamp, accountPseudo, accountAvatar, invitedPseudo, invitedAvatar, cumulatedViews, onAccept, onDecline, onBlock, onUnlock, onQuit }) {
    const twitchId = useTwitchId();

    return (
        <li className="invite-elt">
            <img src={accountAvatar} alt="" className="thumbnail thumbnail1" />
            <div><span className="label">Emetteur </span>{accountPseudo}</div>
            <img src={invitedAvatar} alt="" className="thumbnail thumbnail1" />
            <div><span className="label">Destinataire </span>{invitedPseudo}</div>
            <div><span className="label">Vues cumulées </span><span>{Number(cumulatedViews).toLocaleString('fr')}</span></div>
            <div className='actions'>
                {accountId !== twitchId && status === "AWAITING" && <a className="btn btn-small action accept" onClick={onAccept}>Accepter</a>}
                {accountId !== twitchId && status === "AWAITING" && <a className="btn btn-small action decline" onClick={onDecline}>Refuser</a>}
                {accountId !== twitchId && status === "AWAITING" && <a className="btn btn-small action block" onClick={onBlock}>Bloquer</a>}
                {accountId !== twitchId && status === "REJECTED" && <a className="btn btn-small action unlock" onClick={onUnlock}>Débloquer</a>}
                {status === "VALIDATED" && <a className="btn btn-small action block" onClick={onQuit}>Quitter</a>}
            </div>
        </li>
    )
}

export default function MyInvites() {
    const isAuth = useIsAuthenticated();
    const apiToken = useApiToken();
    const twitchId = useTwitchId();

    const [validated, setValidated] = useState([]);
    const [blocked, setBlocked] = useState([]);

    const [reloadCounter, setReload] = useState(0);

    const [waiting, setWaiting] = useState([]);

    useEffect(() => {
        if (isAuth) {
            listInvites("VALIDATED", apiToken).then(setValidated);
            listInvites("AWAITING", apiToken).then(setWaiting);
            listInvites("REJECTED", apiToken)
                .then(invits => invits.filter(elt => elt.accountId !== twitchId)).then(setBlocked);

        }
    }, [isAuth, reloadCounter]);

    const acceptInviteLb = (accountId, apiToken) => (e) => {
        acceptInvite(accountId, apiToken)
            .then(() => setReload(reloadCounter + 1))
            .catch(e => {
                console.error(e);
                alert("ERROR");
            }
            );
    };

    const declineInviteLb = (accountId, apiToken) => (e) => {
        declineInvite(accountId, apiToken)
            .then(() => setReload(reloadCounter + 1))
            .catch(e => {
                console.error(e);
                alert("ERROR");
            }
            );
    };

    const blockInviteLb = (accountId, apiToken) => (e) => {
        blockInvite(accountId, apiToken)
            .then(() => setReload(reloadCounter + 1))
            .catch(e => {
                console.error(e);
                alert("ERROR");
            }
            );
    };

    const hasWarning = (validated || []).concat(waiting || []).some(elt => elt.cumulatedViews < 5000);

    if (!isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <div className="container my-invites">
            <div className="content">
                <h1>Mes invitations</h1>
                {/*hasWarning && <div className="warning-elt">Vous serez affichés dans la liste quand vous aurez dépassé 5000 vues cumulées sur Twitch</div>*/}
                {validated && validated.length > 0 &&
                    (<Fragment>
                        <h2>Mes invitations acceptées </h2>
                        <ul className='invite-list'>
                            {validated.map(elt => <Invite
                                key={elt.accountId + '' + elt.invitedId}
                                onQuit={declineInviteLb(twitchId !== elt.accountId ? elt.accountId : elt.invitedId, apiToken)}
                                {...elt}
                            />)}
                        </ul>
                    </Fragment>)
                }
                <h2>Mes invitations en attente </h2>
                <ul className='invite-list'>
                    {waiting.map(elt => <Invite
                        key={elt.accountId + '' + elt.invitedId}
                        onAccept={acceptInviteLb(elt.accountId, apiToken)}
                        onDecline={declineInviteLb(elt.accountId, apiToken)}
                        onBlock={blockInviteLb(elt.accountId, apiToken)}
                        {...elt}
                    />)
                    }
                </ul>

                {blocked && blocked.length > 0 &&
                    (<Fragment>
                        <h2>Liste des invitations bloquées</h2>
                        <ul className='invite-list'>
                            {blocked.map(elt => <Invite
                                key={elt.accountId + '' + elt.invitedId}
                                onUnlock={declineInviteLb(elt.accountId, apiToken)}
                                {...elt}
                            />)}
                        </ul>
                    </Fragment>)
                }
            </div>
        </div>
    );
}