import React, { Fragment, useState, useContext } from "react";
import { UserContext } from "./providers/user";

import { inviteToGroup } from "./server-config/invite";
import { useIsAuthenticated } from './hooks/access';

import "./PreInscription.css"
import { twitchFetch } from "./utilities/twitch-fetch";

// inviteToGroup

function submit(e, userName, token, apiToken) {
    e.preventDefault();
    e.stopPropagation();

    if (!userName) {
        alert('Aucun pseudo renseigné')
        return;
    }

    twitchFetch(
        { url: `https://api.twitch.tv/helix/users?login=${userName}`, method: 'GET' },
        null,
        { headers: { Authorization: 'OAuth ' + token } }
    ).then(jsondata => {
        if (jsondata.data && jsondata.data.length > 0) {
            const partnerId = jsondata.data[0].id;
            return inviteToGroup(partnerId, apiToken);
        } else {
            alert('Aucun compte Twitch correspondant trouvé')
        }
    }).then(() => {
        alert("La demande de duo a été envoyée à votre partenaire. Celui-ci doit la valider dans 'Mes invitations' avant de procéder à l'inscription");
    })
        .catch((e) => {
            console.error(e);
            alert("Erreur : Impossible d'inviter le joueur dans votre équipe. Assurez vous qu'il se soit déjà connecté une fois sur le site, et vérifiez dans 'Mes invitations' si vous avez déjà fait une demande, vous ne pouvez pas recommencer. ")
        })

}
// onKeyUp={this.saveOnEnter}

// searchUser(event) {
//     if (event.key === 'Enter') {
//         event.preventDefault();
//         event.stopPropagation();
//         let userName = this.state.usersearch;
//         let id = null;
//         if (userName) {
//             twitchFetch({ url: `https://api.twitch.tv/helix/users?login=${userName}`, method: 'GET' })
//                 .then(jsondata => {
//                     if (jsondata.data && jsondata.data.length > 0) {
//                         id = jsondata.data[0].id;
//                     }
//                     this.setState({ usersearch: null, twitchId: id });
//                 })
//         }
//     }
// },

function handleFieldChange(setState) {
    return ({ target: { value } }) => setState(value);
}

export default function PreInscription() {
    const [pseudo, setPseudo] = useState('');
    const { profile } = useContext(UserContext);
    const isAuth = useIsAuthenticated();

    const { token, apiToken, username } = profile || {};


    return (
        <div className="container inscription">
            <div className="content">
                <h1>Inviter un joueur à rejoindre mon équipe pour le GamePassChallenge</h1>
                {isAuth && <Fragment><p>Pour pouvoir ajouter votre partenaire, celui-ci doit également s'être déjà connecté une fois sur le site  avec Twitch.</p>
                    <form className="" onSubmit={e => submit(e, pseudo, token, apiToken)}>
                        <input type="text" value={pseudo} onChange={handleFieldChange(setPseudo)} placeholder="Pseudo Twitch de votre partenaire" />
                        <input type="submit" name="" value="Inviter ce joueur à rejoindre mon équipe" />
                    </form></Fragment>}
                {!isAuth && <p>Vous devez vous connecter pour vous pré-inscrire.</p>}
            </div>
        </div>
    );
}
