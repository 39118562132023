import React, { useContext } from 'react';
import localForage from 'localforage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'

import fetch from '../server-config/easy-fetch';
import { UserContext } from '../providers/user';


function doLogout(token, dispatchUser) {
    if (token) {
        fetch({ url: `https://api.twitch.tv/kraken/oauth2/revoke?client_id=${process.env.REACT_APP_CLIENT_ID}&token=${token}`, method: 'POST' })
            .catch(() => { });
    }
    localForage.clear();
    dispatchUser({ type: 'reset' });
}


function UserInfos() {
    const { profile, dispatchUser } = useContext(UserContext);
    const { token, username, logo } = profile || {};
    return (
        <div className='user-infos'>
            <div
                className='user-infos-image'
                style={{ backgroundImage: `url(${logo})` }}
            />
            <div className='user-infos-name'>
                <div className='user-infos-name-welcome'>
                    {'Bienvenue'}
                </div>
                <div className='user-infos-name-value'>
                    {username}
                </div>
            </div>
            <button
                className='user-infos-logout'
                onClick={() => doLogout(token, dispatchUser)}
            >
                <FontAwesomeIcon icon={faPowerOff} />
            </button>
        </div>
    );
}

export default UserInfos;
