import fetch from './easy-fetch';
import { apiRoot } from './index';

export function createGroup(group, accessToken) {
    return fetch({
        url: apiRoot + 'me/group/register',
        method: 'POST'
    }, group, { headers: { Authorization: accessToken } });
}

export function updateGroup(group, accessToken) {
    return fetch({
        url: apiRoot + `me/group/register`,
        method: 'PUT'
    }, group, { headers: { Authorization: accessToken } });
}

export function getCurrentGroup(accessToken) {
    return fetch({
        url: apiRoot + `me/group/register`,
        method: 'GET'
    }, null, { headers: { Authorization: accessToken } });
}

export function listAll(accessToken) {
    return fetch({
        url: `${apiRoot}inscriptions`,
        method: 'GET'
    }, null, { headers: { Authorization: accessToken } });
}