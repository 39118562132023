import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import wow_rule from "../assets/images/jeux/wow_rules.jpg";
import worms_rule from "../assets/images/jeux/worms_rules.jpg";

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            width: 800,
            arrows: false,
            slide: '.slide'
        };
        return (
            <div>
                <Slider {...settings}>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">

                                <p>Bien que le mode de jeu soit le même durant la phase de poule et la phase d'arbre éliminatoire, il y a tout de même quelques différences.</p>

                                <h4>Phase de poule : </h4>

                                <p>3 parties de Minecraft sont jouées lors des phases de poule. Les 98 joueurs s’affrontent en PvP (Player versus Player) sur une carte de taille 1500 -1500. Le classement (98 à 1) et le nombre d’éliminations individuels à la fin de chaque partie attribuent un certain nombre de points au joueur. La somme des scores des équipiers les place dans le classement en équipe (49 à 1) de la partie.</p>

                                <p><b>ATTENTION : bien que vos scores se cumulent, vous n’êtes pas en équipe durant l’épreuve, et devez donc communiquer pour ne pas éliminer votre équipier.</b></p>

                                <h4>Exemple au terme d’une partie :</h4>

                                <p>
                                    <span className="text_green">Joueur 1</span> de <span className="text_green">l’équipe 1</span> fait top 1 et 12 kills et se voit attribuer un nombre de points, noté A.<br />
                                    <span className="text_green">Joueur 2</span> de <span className="text_green">l’équipe 1</span> fait top 12 et 2 kills et se voit attribuer un nombre de points, noté B.<br />
                                    <span className="text_blue">Joueur 3</span> de <span className="text_blue">l’équipe 2</span> fait top 98 et 0 kill et se voit attribuer un nombre de points, noté C.<br />
                                    <span className="text_blue">Joueur 4</span> de <span className="text_blue">l’équipe 2</span> fait top 8 et 9 kills et se voit attribuer un nombre de points, noté D.<br />
                                    <span className="text_red">Joueur 5</span> de <span className="text_red">l’équipe 3</span> fait top 194 et 0 kill et se voit attribuer un nombre de points, noté E.<br />
                                    <span className="text_red">Joueur 6</span> de <span className="text_red">l’équipe 3</span> fait top 51 et 6 kills et se voit attribuer un nombre de points, noté F.<br />
                                    etc… pour les 98 joueurs
                                </p>

                                <p><b>L’équipe avec le score le plus élevé se classe 1ère.</b></p>

                                <p>
                                    <span className="text_green">L’équipe 1</span> a donc un score de A+B et se classe 1ère sur 49 équipes (Top1)<br />
                                    <span className="text_blue">L’équipe 2</span> a donc un score de C+D et se classe 2ème sur 49 équipes (Top2)<br />
                                    <span className="text_red">L’équipe 3</span> a donc un score de E+F et se classe 3ème sur 49 équipes (Top3)<br />
                                    etc… jusqu’au 49/49
                                </p>

                                <p>Un nombre de points (égal pour chaque jeu de la phase de poule) correspondant au barème du jeu pour la phase de poule est attribué à chaque équipe en fonction de leur place dans le classement des équipes. </p>
                                <h4>Phase d'arbre éliminatoire (ou pick & ban) :</h4>
                                <p>Durant cette phase, les équipes s’affrontent en 2v2 dans une mini arène sur le même mode de jeu que celui de la phase de poule et jusqu’à ce qu’une équipe soit éliminée. Les deux équipes jouent en BO3 (la meilleure sur 3 manches) et celle qui l’emporte gagne le point pour avancer dans le tournoi.</p>
                                <h4>Voici les règles du tournoi Minecraft pour les phases de poule ET de l’arbre éliminatoire :</h4>

                                <ul>
                                    <li>Les joueurs apparaissent aléatoirement dans l’arène</li>
                                    <li>Vous disposez de 3 barres de vie complètes (10 coeurs x3) : armure en fer, armure mi-fer mi-cuir, armure en cuir</li>
                                    <li>A chaque vie, vous avez un arc infinity, une épée en pierre et 32 blocs de sable</li>
                                    <li>Il y aura des points attribués pour les éliminations et la survie</li>
                                    <li>Un drop par minute arrive dans la partie</li>
                                    <li>La zone rétrécit au fil du temps</li>
                                    <li>A chaque vie perdue, le joueur est téléporté de façon aléatoire dans la zone de jeu</li>
                                    <li>Pack de texture autorisés mais xray interdit</li>
                                    <li>mods autorisés mais Damage indicators interdit</li>
                                    <li>Pas de triche sous peine de sanction</li>
                                    <li>Optifine est autorisé</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Interdiction de paniquer si vous voyez Aypierre sortir d’un portail du Nether</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc columns2">
                                <p>Sur World of Warcraft, chaque équipe s’affronte via deux courses à l’expérience (“leveling race”, ou “concours de pex”) sur le patch actuel des serveurs officiels. Une première où tout le monde est obligé de créer un Orc Guerrier, puis une seconde qui sera dévoilée le jour des phases de poule. La course démarre au moment où ZeratoR donne le top et chaque joueur doit alors cliquer sur “Entrer dans le jeu” (voir ci-dessous).</p>
                            </div>
                            <div className="bloc columns2">
                                <img src={wow_rule} alt="Screenshot WoW" />
                                <p>Screenshot du jeu avec mise en évidence du bouton qu’il faudra presser au moment où vous entendrez le “GO” de ZeratoR</p>
                            </div>
                            <div className="bloc">
                                <p>Vous avez 45 minutes pour atteindre le plus haut niveau d’expérience. Votre niveau/points d’expérience au niveau individuel détermine votre position dans le classement général (de 98 à 1), et les deux scores additionnés de votre équipe déterminent votre position dans le classement général des équipes (de 49 à 1).</p>
                                <h4>Par exemple, au terme des 45 minutes :</h4>
                                <p>
                                    <span className="text_green">Joueur 1</span> de <span className="text_green">l’équipe 1</span> est niveau 9 avec son orc guerrier et possède <b>2000</b> points d‘expérience<br />
                                    <span className="text_green">Joueur 2</span> de <span className="text_green">l’équipe 1</span> est niveau 8 avec son orc guerrier et possède <b>4600</b> points d‘expérience.<br />
                                    <span className="text_blue">Joueur 3</span> de <span className="text_blue">l’équipe 2</span> est niveau 8 avec son orc guerrier et possède <b>1000</b> points d‘expérience<br />
                                    <span className="text_blue">Joueur 4</span> de <span className="text_blue">l’équipe 2</span> est niveau 7 avec son orc guerrier et possède <b>5400</b> points d‘expérience.<br />
                                    <span className="text_red">Joueur 5</span> de <span className="text_red">l’équipe 3</span> est niveau 9 avec son orc guerrier et possède <b>7456</b> points d‘expérience<br />
                                    <span className="text_red">Joueur 6</span> de <span className="text_red">l’équipe 3</span> est niveau 5 avec son orc guerrier et possède <b>5487</b> points d‘expérience.<br />
                                    etc… pour les 98 joueurs
                                </p>
                                <h4>L’équipe avec le score le plus élevé se classe 1ère.</h4>
                                <p>
                                    <span className="text_green">L’équipe 1</span> a donc un score de <b>17/6600</b> et se classe 1ère sur 49 équipes (Top1)<br />
                                    <span className="text_blue">L’équipe 2</span> a donc un score de <b>15/6400</b> et se classe 2ème sur 49 équipes (Top2)<br />
                                    <span className="text_red">L’équipe 3</span> a donc un score de <b>14/12943</b> et se classe 3ème sur 49 équipes (Top3)<br />
                                    etc… jusqu’au 49/49
                                </p>
                                <p>Un nombre de points (égal pour chaque jeu de la phase de poule) correspondant au barème du jeu pour la phase de poule est attribué à chaque équipe en fonction de leur place dans le classement des équipes.</p>
                                <h4>Voici les règles à respecter pour le concours d’expérience sur World of Warcraft,<br />sous peine d’être disqualifié (0 point pour l’équipe complète à cette épreuve) :</h4>
                                <ul>
                                    <li>Pas de parrainage entre les comptes impliqués dans la course</li>
                                    <li>Pas d’objets héritages (y compris la monture héritage), tous à pied !</li>
                                    <li>Pas d’objets qui augmentent le gain d’expérience (potions, équipements...)</li>
                                    <li>Pas d’aide de joueur extérieur à #ZLAN</li>
                                    <li>Les joueurs ont le choix de grouper (ou non) uniquement avec les joueurs de #ZLAN</li>
                                    <li>Les joueurs ne peuvent pas se téléporter/se déplacer à une autre zone de départ</li>
                                    <li>Si un joueur est invité dans le jeu par un des admins (liste fournie à l’avance), il est obligé d’accepter</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel (sauf addons)</li>
                                    <li>Interdiction de râler si, soi-disant, on vous a “nieunieunieu volé un sanglier”</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <p>La compétition est jouée en mode round sur 3 cartes faites par ZeratoR. Il y aura 5 rounds par carte, en commençant par un tour de chauffe (warmup) sur chaque carte. A chaque round, un nombre de points est attribué aux joueurs en fonction de leurs positions dans le classement. Au terme des 3 cartes jouées, on a donc un classement en fonction des points attribués tout au long des 15 rounds et chaque joueur obtient un classement sur les 98 joueurs présents. La somme des scores des équipiers les place dans le classement en équipe (49 à 1) de la partie. </p>
                                <h4>Exemple au terme de 20 rounds (5 rounds sur 3 cartes) :</h4>
                                <p>
                                    <span className="text_green">Joueur 1</span> de <span className="text_green">l’équipe 1</span> a un score en jeu affiché de 15 778<br />
                                    <span className="text_green">Joueur 2</span> de <span className="text_green">l’équipe 1</span> a un score en jeu affiché de 16 487<br />
                                    <span className="text_blue">Joueur 3</span> de <span className="text_blue">l’équipe 2</span> a un score en jeu affiché de 25 464<br />
                                    <span className="text_blue">Joueur 4</span> de <span className="text_blue">l’équipe 2</span> a un score en jeu affiché de 5 645<br />
                                    <span className="text_red">Joueur 5</span> de <span className="text_red">l’équipe 3</span> a un score en jeu affiché de 3 567<br />
                                    <span className="text_red">Joueur 6</span> de <span className="text_red">l’équipe 3</span> a un score en jeu affiché de 7 985<br />
                                    etc… pour les 98 joueurs
                                </p>
                                <p><b>L’équipe avec le score le plus élevé se classe 1ère.</b></p>
                                <p>
                                    <span className="text_green">L’équipe 1</span> a donc un score de 32 265 et se classe 1ère sur 49 équipes (Top1)<br />
                                    <span className="text_blue">L’équipe 2</span> a donc un score de 31 109 et se classe 2ème sur 49 équipes (Top2)<br />
                                    <span className="text_red">L’équipe 3</span> a donc un score de 11 552 et se classe 3ème sur 49 équipes (Top3)<br />
                                    etc… jusqu’au 49/49
                                 </p>
                                <p>Un nombre de points (égal pour chaque jeu de la phase de poule) correspondant au barème du jeu pour la phase de poule est attribué à chaque équipe en fonction de leur place dans le classement des équipes.</p>
                                <h4>Voici les règles du tournoi Trackmania pour les phases de poule ET de l’arbre éliminatoire :</h4>

                                <ul>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Ne pas toucher les bords (sous peine de ralentissement de votre voiture par le jeu)</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <p>3 parties de H1Z1 Battle Royale sont jouées lors des phases de poule. Les 49 équipes s’affrontent en BR2. Le classement et le nombre d’éliminations à la fin de chaque partie attribuent un certain nombre de points à l’équipe. Le score de l’équipe les place dans le classement (49 à 1) de la partie.</p>
                                <h4>Exemple au terme d’une partie :</h4>
                                <p>
                                    <span className="text_green">L’équipe 1</span> fait top 1 et 12 kills et se voit attribuer un nombre de points.<br />
                                    <span className="text_blue">L’équipe 2</span> fait top 98 et 0 kill et se voit attribuer un nombre de points.<br />
                                    <span className="text_red">L’équipe 3</span> fait top 194 et 0 kill et se voit attribuer un nombre de points.<br />
                                    etc… pour les 49 équipes
                                </p>
                                <p><b>L’équipe avec le score le plus élevé se classe 1ère. </b></p>
                                <p>
                                    <span className="text_green">L’équipe 1</span> se classe 1ère sur 49 équipes (Top1)<br />
                                    <span className="text_blue">L’équipe 2</span> se classe 2ème sur 49 équipes (Top2)<br />
                                    <span className="text_red">L’équipe 3</span> se classe 3ème sur 49 équipes (Top3)<br />
                                    etc… jusqu’au 49/49
                                </p>
                                <p>Un nombre de points (égal pour chaque jeu de la phase de poule) correspondant au barème du jeu pour la phase de poule est attribué à chaque équipe en fonction de leur place dans le classement des équipes. </p>
                                <h4>Voici les règles du tournoi H1Z1 Battle Royale pour les phases de poule :</h4>
                                <ul>
                                    <li>Les 3 parties seront jouées sur la map Z2</li>
                                    <li>Aucune arme, skin, équipement interdit</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Obligation de crier “CHINA NUMBER ONE” si vous affrontez MoMaN et le mettez à terre</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <p>Le texte rédigé par ZeratoR et lu par ce dernier est retransmis dans la salle à l’aide d’un dispositif audio. Un logiciel spécialement codé pour l’occasion vous est fourni sur place par l’organisation. Un premier texte de test sera lu pour vous aider à prendre en main le programme et son fonctionnement. Lors de la lecture du texte de l’épreuve, vous devez rédiger trois paragraphes dictés du plus simple au plus difficile. </p>
                                <p>A chaque fin de paragraphe, le logiciel vous donnera votre classement actuel parmi tous les participants à l’épreuve afin que vous puissiez faire un effort si votre position ne vous sied pas suffisamment. Le texte est lu une première fois sans la ponctuation et à vitesse de lecture normale, puis dicté avec ponctuation et orthographe des noms propres/anglicismes/néologismes s’il y en a. Un temps de relecture de 2 minutes est donné à chaque joueur, au terme desquels le paragraphe sera automatiquement validé et corrigé par le logiciel.</p>
                                <p>Chaque joueur démarre la dictée avec 20 points. A chaque fois qu’il fait une faute, le logiciel lui attribue -1 point (score négatif possible). A chaque paragraphe, un nombre de points est attribué aux joueurs en fonction de leur nombre de fautes. Au terme des 3 paragraphes dictés, le joueur voit son score final affiché sur le logiciel. La somme des scores des équipiers les place dans le classement en équipe (98 à 1) de la partie. </p>
                                <h4>Exemple au terme de l’épreuve :</h4>
                                <p>
                                    <span className="text_green">Joueur 1</span> de <span className="text_green">l’équipe 1</span> de la <span className="text_pink">poule 1</span> finit la dictée avec 19 points.<br />
                                    <span className="text_green">Joueur 2</span> de <span className="text_green">l’équipe 1</span> de la <span className="text_pink">poule 1</span> finit la dictée avec 8 points.<br />
                                    <span className="text_blue">Joueur 3</span> de <span className="text_blue">l’équipe 2</span> de la <span className="text_yellow">poule 2</span> finit la dictée avec 2 points.<br />
                                    <span className="text_blue">Joueur 4</span> de <span className="text_blue">l’équipe 2</span> de la <span className="text_yellow">poule 2</span> finit la dictée avec -87 points.<br />
                                    <span className="text_red">Joueur 5</span> de <span className="text_red">l’équipe 3</span> de la <span className="text_pink">poule 1</span> finit la dictée avec -20 points.<br />
                                    <span className="text_red">Joueur 6</span> de <span className="text_red">l’équipe 3</span> de la <span className="text_pink">poule 1</span> finit la dictée avec -4877 points.<br />
                                    etc… pour les 196 joueurs
              </p>
                                <p><b>Les équipes sont classés dans leurs poules respectives en fonction de leur nombre de points.</b></p>
                                <p>
                                    <span className="text_green">L’équipe 1</span> a donc un score de 27 et se classe dans la <span className="text_pink">poule 1</span> en fonction du score des autres<br />
                                    <span className="text_blue">L’équipe 2</span> a donc un score de -85 et se classe dans la <span className="text_yellow">poule 2</span> en fonction du score des autres<br />
                                    <span className="text_red">L’équipe 3</span> a donc un score de -4897 et se classe dans la <span className="text_pink">poule 1</span> en fonction du score des autres<br />
                                    etc… jusqu’au 98/98
              </p>
                                <h4>Voici les règles de la dictée pour les phases de poule :</h4>

                                <ul>
                                    <li>Interdiction de lire son t’chat (streamer)</li>
                                    <li>Interdiction de parler à son voisin (cela pourrait le déconcentrer pendant la dictée)</li>
                                    <li>Interdiction de COPIER sur le VOISIN !</li>
                                    <li>Interdiction de geindre à l’écoute ou à l'occurrence d’un mot non usuel prononcé par le quidam locuteur</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <h4>Phase d'arbre éliminatoire (ou pick & ban) :</h4>
                                <p>Durant cette phase, les équipes s’affrontent en 2v2, les règles sont celles de base de Rocket League. Les deux équipes jouent en BO3 (la meilleure sur 3 manches) et celle qui l’emporte gagne le point pour avancer dans le tournoi.</p>
                                <h4>Voici les règles du match sur Rocket League :</h4>

                                <ul>
                                    <li>Pas de mutateurs particuliers (jeu sur configuration vanilla)</li>
                                    <li>Première arène : Colisée Utopia puis le perdant choisit</li>
                                    <li>Aucun choix de stade interdit pour le perdant</li>
                                    <li>Matchs de 5 minutes (temps en jeu + prolongations)</li>
                                    <li>Aucun élément esthétique sur les voitures interdit</li>
                                    <li>Aucun réglage caméra interdit</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Interdiction d’être stoïque en cas de but magnifique</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <h4>Phase d'arbre éliminatoire (ou pick & ban) :</h4>
                                <p>Durant cette phase, les équipes s’affrontent en 2v2, les règles sont celles de base de Starcraft II. Les deux équipes jouent en BO3 (la meilleure sur 3 manches) et celle qui l’emporte gagne le point pour avancer dans le tournoi. Le pack de cartes est le suivant : </p>

                                <ul>
                                    <li>Cité émeraude EC</li>
                                    <li>Canyon aride</li>
                                    <li>Sanctuaire de l’Izul</li>
                                    <li>Efflorescence EC</li>
                                    <li>Exil du traître</li>
                                    <li>Complexe luxuriant</li>
                                    <li>Ulzaan</li>
                                </ul>

                                <h4>Voici les règles du match sur Starcraft II :</h4>

                                <ul>
                                    <li>Première carte : Ulzaan puis le perdant choisit parmi le pack de cartes</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Interdiction de prétendre qu’une race est “IMBA” au moment où vous rage-quittez parce que vous ne vous êtes pas assez entraîné</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc columns2">
                                <p>Durant cette phase, les équipes jouent en 2v2 et la partie s’arrête quand les deux joueurs d’une même équipe sont éliminés. Les équipes jouent en BO3 (la meilleure sur 3 manches) et celle qui l’emporte gagne le point pour avancer dans le tournoi.</p>
                                <p><b>ATTENTION : Les deux joueurs doivent choisir la même couleur dans le jeu pour être considérés en équipe.</b></p>
                            </div>
                            <div className="bloc columns2">
                                <img src={worms_rule} alt="Screenshot Worms" />
                                <p>La mise en place de la partie devra ressembler à ça, avec 2 équipes en bleu, et 2 équipes en rouge</p>
                            </div>
                            <div className="bloc">

                                <h4>Voici les règles du match sur Worms W.M.D. :</h4>

                                <ul>
                                    <li>Mode classique avec durée de la manche à 15 et manches à gagner à 2 (puisque c’est un BO3) (voir screenshot ci-dessus)</li>
                                    <li>4 vers par joueur</li>
                                    <li>Première carte : Campagne, puis le perdant choisit</li>
                                    <li>Carte “Mode forts” interdite</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Interdiction de s’énerver sur son coéquipier s’il rate une grenade et vous tue un ver</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <p>Durant cette phase, les joueurs s’affrontent dans une seule course de “speedrun co-op” sur Portal 2, ce qui implique que la première équipe qui arrive à terminer le jeu a gagné. Si aucune équipe ne termine le jeu en moins de 45 minutes, elles sont départagées en fonction de leur avancée dans le jeu (vérifiée par un arbitre sur place). Voici le record du monde actuel de la catégorie #ZLAN en speedrun co-op (avec les règles spécifiques énoncées ci-dessous) : <a className="text_blue" href="https://www.youtube.com/watch?v=owJGwYh9kYs" target="_blank">https://www.youtube.com/watch?v=owJGwYh9kYs</a>.</p>
                                <h4>Voici les règles du match sur Portal 2 :</h4>

                                <ul>
                                    <li>Lien du Google Doc détaillant les techniques/glitchs autorisés : <a className="text_blue" href="https://docs.google.com/spreadsheets/d/1x_zsImcEBaL8kdylE43vx-tfYpldWsoJB0KTgAIwQEg/edit#gid=713874328" target="_blank">GDOC PORTAL 2 GLITCHS</a></li>
                                    <li>Regardez bien le Google Doc car certaines techniques peuvent vous disqualifier de l’épreuve, <b>il est donc très important et impératif d’y jeter un oeil.</b></li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel, mis à part les logiciels qui permettent de mesurer le temps (voir avec les admins sur place)</li>
                                    <li>Interdiction de dire que le gâteau est un mensonge</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="contenu-jeu">
                            <div className="bloc">
                                <p>Durant cette phase, les équipes jouent en 2v2 et la partie s’arrête quand les deux joueurs d’une même équipe sont éliminés. Les équipes jouent en BO3 (la meilleure sur 3 manches) et celle qui l’emporte gagne le point pour avancer dans le tournoi.</p>
                                <h4>Voici les règles du match sur Super Smash Bros Ultimate :</h4>

                                <ul>
                                    <li>Mode 2v2 compétitif</li>
                                    <li>Pas d’objets en jeu (no items)</li>
                                    <li>Ruleset complet ici : <a href="https://docs.google.com/document/d/1DBTNjzp1ndB3e8HASIjzlQSZdSvPd_TOAfo-93cfdW8/edit" target="_blank" className="text_blue">RULESET COMPELT SMASH</a></li>
                                    <li>Vous devez amener vos manettes et les adaptateurs le cas échéant</li>
                                    <li>Vous n’êtes pas obligé d’apporter votre Nintendo Switch (mais vous pouvez !)</li>
                                    <li>Interdiction d’utiliser des programmes tiers modifiant le jeu officiel</li>
                                    <li>Interdiction de dire que vous auriez gagné sur GameCube ou sur Melee</li>
                                    <li>Si vous avez un doute sur une règle qui aurait pu être oubliée, n'hésitez pas à contacter l'organisation (tournoi@z-lan.fr)</li>
                                </ul>

                            </div>
                        </div>
                    </div>

                </Slider>
            </div>
        );
    }
}
