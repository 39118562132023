import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { useIsModo, useIsAuthenticated } from './hooks/access';

import Home from "./Home";
import PreInscription from "./PreInscription";
import StreamerInscrits from "./StreamersInscrits"
import Token from "./Token"
import MyInvites from "./MyInvites";
import Inscription from "./Inscription";
import InscriptionList from "./InscriptionList";

import Cgu from "./Cgu";

export default function Routes() {
    const isAuth = useIsAuthenticated();
    const isModo = useIsModo();
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/pre-inscription" component={PreInscription} />
            <Route path="/inscription" component={Inscription} />
            {isAuth && isModo && <Route path="/inscriptions" component={InscriptionList} />}
            <Route path="/streamers-inscrits" component={StreamerInscrits} />
            <Route path="/my-invites" component={MyInvites} />
            <Route path="/cgu" component={Cgu} />
            <Route path="/:token" component={Token} />
        </Switch>
    )
}
