import React, { useEffect, useState, useContext } from "react";

import { useIsModo } from "./hooks/access";
import { UserContext } from "./providers/user";
import { useIsAuthenticated, useApiToken } from './hooks/access';
import { listAll } from "./server-config/inscription";
import downloadData from "./utilities/download-data";

function Team({ groupData }) {
    return (
        <section className="team">
            <p className="pseudo pseudo1">{groupData.pseudo1}</p>
            <p className="pseudo pseudo2">{groupData.pseudo2}</p>
        </section>);
}

function csvExport(all) {

    const header = 'groupData.groupId;groupData.prenom1;groupData.prenom2;groupData.nom1;groupData.nom2;groupData.pseudo1;groupData.pseudo2;account1.username;account2.username;account1.email;account2.email;groupData.email1;groupData.email2;account1.url;account2.url;account1.twitchId;account2.twitchId;account1.broadcasterType;account2.broadcasterType;account1.views;account2.views;account1.followers;account2.followers;account1.logo;account2.logo;groupData.birthdate1;groupData.birthdate2';

    return evt => {
        evt.preventDefault();

        const data = all.map(obj => header.split(';').map(key => obj[key.toLowerCase()]).join(';'));
        const fileName = 'gpc-inscriptions.csv';

        downloadData([header].concat(data).join('\n'), fileName, 'text/csv');
    }
}

export default function InscriptionList() {
    const [all, setAll] = useState([]);
    const isAuth = useIsAuthenticated();
    const apiToken = useApiToken();
    const isModo = useIsModo();

    useEffect(() => {
        if (isAuth) {
            listAll(apiToken)
                .then(setAll);
        }
    }, [isAuth]);


    return (<div className="container">
        <div className="content">
            <h1>{`Liste des inscrits à la GPC - (${all.length})`}</h1>
            {isModo && <a className="btn action" onClick={csvExport(all)} style={{ display: 'block', 'marginBottom': '15px' }} >Exporter en CSV</a>}

            <div className="list">
                <div className="teams">
                    {all.map((elt, idx) => <Team key={idx} {...elt} />)}
                </div>
            </div>
        </div>
    </div>);
}